<template>
  

  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div> 

 
</template>


<script setup>
 
</script>


<style scoped>

.rw{  
display: flex;
align-items: center;
width: 100%;
height: 40px;
margin-bottom: 10px;
gap: 11px;
margin: 0.4rem auto;
} 

.rw > *{
flex-shrink: 0;
}
 

</style>
